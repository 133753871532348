// Vue
import VueGtag from "vue-gtag";
import TurbolinksAdapter from "vue-turbolinks";
import Vue from "vue";
import { GOOGLEANALYTICSID } from "@root/constants";
import env from "@app/javascript/plugins/env";
import store from "@app/javascript/store/store";
import screenSize from "@app/javascript/plugins/screenSize";
import CarouselFlickity from "@components/carousel/CarouselFlickity.vue";
import ArticleCardNews from "@components/news/ArticleCardNews.vue";
import ListingPage from "@components/news/ListingPage.vue";
import EntryGeoDataMap from "@components/Entry/GeoDataMap.vue";
import EntryDisplayShortcutLinks from "@components/Entry/DisplayShortcutLinks.vue";
import EntryDisplayCaseStudies from "@components/Entry/DisplayCaseStudies.vue";
import EntryDataManual from "@components/Entry/DataManual.vue";
import ButtonGoToLink from "@components/Button/GoToLink";
import BannerExpandable from "@components/Banner/Expandable";
import BannerCookie from "@components/Banner/Cookie";
import EntryDataSummaryDisclaimer from "@components/Entry/DataSummary/Disclaimer.vue";
import EntryDataSummaryHighlights from "@components/Entry/DataSummary/Highlights.vue";
import EntryDataSummaryCharts from "@components/Entry/DataSummary/Charts.vue";
import EntryDataSummaryErrorBanner from "@components/Entry/DataSummary/ErrorBanner.vue";

import "@app/assets/stylesheets/tailwind/tailwind.css";
import "animate.css";

// See /app/views/layouts/application.html.erb to find serverEnviroment
const serverEnviromentIs = {
  production: window.serverEnviroment === "production",
  staging: window.serverEnviroment === "staging",
  development: window.serverEnviroment === "development",
};
const googleGA4ID = serverEnviromentIs.production
  ? GOOGLEANALYTICSID.production
  : GOOGLEANALYTICSID.staging;
Vue.use(screenSize, { store });
Vue.use(TurbolinksAdapter);
Vue.use(env);
Vue.use(VueGtag, {
  config: {
    id: googleGA4ID,
    params: {
      send_page_view: true
    }
  },
  enabled: false,
});
export const eventHub = new Vue();
// Have to set it up like this because Vue is only being used for certain views
document.addEventListener("turbolinks:load", () => {
  if (document.getElementById("v-carousel")) {
    new Vue({
      el: "#v-carousel",
      components: {
        CarouselFlickity,
      },
    });
  }

  if (document.getElementById("v-articles")) {
    new Vue({
      el: "#v-articles",
      components: {
        ArticleCardNews,
      },
    });
  }

  if (document.getElementById("v-listing")) {
    Vue.prototype.$eventHub = new Vue();

    new Vue({
      el: "#v-listing",
      components: {
        ListingPage,
      },
    });
  }
  if (document.getElementById("v-entry-geo-data-map")) {
    new Vue({
      el: "#v-entry-geo-data-map",
      provide: {
        $env: Vue.prototype.$env,
      },
      components: {
        EntryGeoDataMap,
      },
    });
  }
  if (document.getElementById("v-entry-shortcut-links")) {
    new Vue({
      el: "#v-entry-shortcut-links",
      components: {
        EntryDisplayShortcutLinks,
      },
    });
  }
  if (document.getElementById("v-random-case-studies")) {
    new Vue({
      el: "#v-random-case-studies",
      components: {
        EntryDisplayCaseStudies,
      },
    });
  }
  if (document.getElementById("v-data-manual")) {
    new Vue({
      el: "#v-data-manual",
      components: {
        EntryDataManual,
      },
    });
  }

  if (document.getElementById("v-home-hero-button")) {
    new Vue({
      el: "#v-home-hero-button",
      components: {
        ButtonGoToLink,
      },
    });
  }
  if (document.getElementById("v-data-summary-banner")) {
    new Vue({
      el: "#v-data-summary-banner",
      components: {
        BannerExpandable,
      },
    });
  }

  if (document.getElementById("v-data-summary-highlights")) {
    new Vue({
      el: "#v-data-summary-highlights",
      components: {
        EntryDataSummaryHighlights,
      },
    });
  }
  if (document.getElementById("v-data-summary-charts")) {
    new Vue({
      el: "#v-data-summary-charts",
      components: {
        EntryDataSummaryCharts,
      },
    });
  }
  if (document.getElementById("v-data-summary-error-banner")) {
    new Vue({
      el: "#v-data-summary-error-banner",
      components: {
        EntryDataSummaryErrorBanner,
      },
    });
  }
  if (document.getElementById("v-data-summary-disclaimer")) {
    new Vue({
      el: "#v-data-summary-disclaimer",
      components: {
        EntryDataSummaryDisclaimer,
      },
    });
  }
  if (document.getElementById("v-cookie-banner")) {
    new Vue({
      el: "#v-cookie-banner",
      provide() {
        return {
          serverEnviromentIs,
        };
      },
      components: {
        BannerCookie,
      },
    });
  }
});
